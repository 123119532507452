import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import {
  Table,
  useParamsTable,
} from 'core/uiKit/components/tables/Table';

import { getColumnsPpi } from './columns.Ppi';
import { useFilterQueryParamsPpi } from './Filter.Ppi';

 
/**
 * Компонент таблицы __Элементы благоустройства для маломобильных групп населения__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {JSX.Element} - JSX.
 * @example -----
 * <TablePpi
 *  typeId={typeId}
 *  parentTypeId={3}
 * />
 */
export const TablePpi = ({ typeId, parentTypeId }) => {
  const { prepareFilters } = useFilterQueryParamsPpi();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TablePpi',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } =
    useFetchOghTable(queryKey);

  return (
    <Table
      columns={getColumnsPpi()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
