import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { getOghCard } from 'app/api/requests/getOghCard';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Проверка является ли объект или родитель загружен из внешней системы .
 *
 * @returns {boolean}
 */
export const useIsCurrentObjectIsExternalSystem = () => {
  const { attribute, parentInfo } = useGetCurrentCard() || {};
  const { create_type: createType } = attribute || {};
  const { parent_id: parentId } = parentInfo || {};

  const { data: { attribute: { create_type: createTypeParent } = {} } = {} } =
    useQueryAdaptor(['getOghCard', parentId], getOghCard, {
      enabled: Boolean(parentId),
    });

  return (
    createType === 'external_system' || createTypeParent === 'external_system'
  );
};
