import * as queryString from 'query-string';
import { useLocation } from 'react-router-dom';

/**
 * Получение ошибки авторизации.
 *
 * @param {string} error - Сообщение об ошибке.
 * @returns {null|string}
 */
export const useGetErrorMessage = (error) => {
  const queryParams = queryString.parse(useLocation().search);

  if (queryParams['error']) {
    return 'ошибка аутентификации';
  }

  if (error) {
    if (error === 'Authentication Failed: User is disabled') {
      return 'пользователь заблокирован';
    }
    return error;
  }
  return null;
};
