import { get } from 'app/api/crud/get';
import { post } from 'app/api/crud/post';
import { AUTH_1_0_API_URL } from 'config/env';
import { AUTH_URLS_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/AuthUrls';

interface FetchLoginParameters {

  /**
   * Урл Для авторизации.
   */
  [AUTH_URLS_NAME]: string;

  /**
   * Логин.
   */
  username: string;

  /**
   * Пароль.
   */
  password: string;
}

/**
 * FetchLogin.
 *
 * @param data - Данные для логина.
 * @returns {Promise<*|Response>}
 */
export const fetchLogin = async (
  data: FetchLoginParameters,
): Promise<unknown | Response> => {
  let url = data[AUTH_URLS_NAME] || '';
  let host = undefined;

  if (url.includes('path_api_1.0')) {
    url = url.replace('path_api_1.0', '');
    host = AUTH_1_0_API_URL;
  }

  return await post(
    url,
    {
      j_password: data.password,
      j_username: data.username,
      password: data.password,
      username: data.username,
    },
    {
      host,
      jopa: Boolean(host),
      parseResponse: !host,
    },
  );
};

/**
 * Logout.
 *
 * @returns {Promise<*|Response>}
 */
export const logout = async (): Promise<unknown | Response> =>
  await get('/logout', null, { parseResponse: false });
