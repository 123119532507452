import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import {
  Table,
  useParamsTable,
} from 'core/uiKit/components/tables/Table';

import { getColumnsTreesShrubs } from './columns.TreesShrubs';
import { useFilterQueryParamsTreesShrubs } from './Filter.TreesShrubs';

 
/**
 * Компонент таблицы __Деревья/Кустарники__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {JSX.Element} - JSX.
 * @example -----
 * <TableRelief typeId={typeId} parentTypeId={2}/>
 */
export const TableTreesShrubs = ({
  typeId,
  parentTypeId,
}) => {
  const { prepareFilters } =
    useFilterQueryParamsTreesShrubs();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableTreesShrubs',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const {
    data: table,
    isLoading,
    count,
  } = useFetchOghTable(queryKey);

  return (
    <Table
      columns={getColumnsTreesShrubs()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
