import { validateRepairsInfoListPlan } from './validate.RepairsInfoListPlan';

describe('ValidateRepairsInfoListPlanSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(validateRepairsInfoListPlan({})).toStrictEqual({
      date_end: 'обязательное поле',
      date_start: 'обязательное поле',
      files: 'обязательное поле',
      property: 'обязательное поле',
    });
  });
});
