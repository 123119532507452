/* eslint-disable */
import * as env from 'config/env';

import { getUrlWithParams } from './getUrlWithParams';
import { handleErrors } from './handleErrors';
import { toJson } from './middlewares';
import { turn_into_snake_case } from './turn_into_snake_case';

export interface GetOptions {
  host?: string;
  credentials?: RequestCredentials;
  parseResponse?: boolean;
  headers?: object;
  jsonBody?: boolean;
  convertIntoSnakeCase?: boolean;
}

/**
 * Метод-запрос получения __GET__.
 *
 * @param methodUrl - Метод  запроса  он же урл.
 * @param [queryParams] - Параметры запроса.
 * @param [options] - Дополнительные параметры.
 * @param [options.host] - Хост, (начальный урл).
 * @param [options.credentials] - Опция credentials.
 * @param [options.parseResponse] - Флаг, что нужно parseResponse.
 * @param [options.headers] - Дополнительные параметры для хедера.
 * @param [options.jsonBody] - Флаг jsonBody.
 * @param [options.convertIntoSnakeCase] - Флаг, нужно ли конвертировать в CamelCase.
 * @returns {*}
 */
export const get = async <T = unknown>(
  methodUrl: string,
  queryParams?: object | null,
  options?: GetOptions | null,
): Promise<T> => {
  // todo: перейти на env.

  const {
    convertIntoSnakeCase = true,
    credentials = 'include',
    headers,
    host = env.ODS_API_URL,
    parseResponse = true,
    ...fetchOptions
  } = options || {};

  const urlWithParams = getUrlWithParams(
    host,
    methodUrl,
    convertIntoSnakeCase ? turn_into_snake_case(queryParams) : queryParams,
  );

  const queryOptions: RequestInit = {
    credentials,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      ...headers,
    },
    method: 'GET',
    ...fetchOptions,
  };

  const resp = await fetch(urlWithParams, queryOptions);

  await handleErrors(resp);

  return parseResponse ? toJson(resp) : resp;
};
