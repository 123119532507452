import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import {
  Table,
  useParamsTable,
} from 'core/uiKit/components/tables/Table';

import { getColumnsTechPlace } from './columns.TechPlace';
import { useFilterQueryParamsTechPlace } from './Filter.TechPlace';

 
/**
 * Компонет таблицы __Производственные некапитальные объекты__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id объекта ОГX текущего.
 * @param {number} props.parentTypeId - Id объекта ОГX родительского.
 * @returns {JSX.Element} - JSX.
 * @example
 * <TableTechPlace typeId={typeId} parentTypeId={parentTypeId}/>
 */
export const TableTechPlace = ({
  typeId,
  parentTypeId,
}) => {
  const { prepareFilters } =
    useFilterQueryParamsTechPlace();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableTechPlace',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } =
    useFetchOghTable(queryKey);

  return (
    <Table
      columns={getColumnsTechPlace()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
