import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import { Table, useParamsTable } from 'core/uiKit/components/tables/Table';
import PropTypes from 'prop-types';

import { getColumnsRedBookPlant } from './columns.RedBookPlant';
import { useFilterQueryParamsRedBookPlant } from './Filter.RedBookPlant';

 
/**
 * Таблица __Растения Красной книги__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - TypeId паспорта.
 * @param {number} props.parentTypeId - Родительский TypeId.
 * @returns {JSX.Element} - JSX.
 * @example
 * <TableRedBookPlant typeId={typeId} parentTypeId={parentTypeId}/>
 */
export const TableRedBookPlant = ({ typeId, parentTypeId }) => {
  const { prepareFilters = {} } = useFilterQueryParamsRedBookPlant();
  const { queryTable = {} } = useParamsTable();

  const queryKey = [
    'TableRedBookPlant',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } = useFetchOghTable(queryKey);

  return <Table columns={getColumnsRedBookPlant()} data={table || []} isLoading={isLoading} total={count} />;
};

TableRedBookPlant.propTypes = {
   
/**
 * Id типа ОГХ.
 */
  typeId: PropTypes.number.isRequired,
};
