 
/**
 * @typedef {import('core/uiKit/components/tables/Table/Table').Cell} Cell
 */
 
/* eslint-disable */
/**
 * Колонка __Диаметр на высоте 1,3 м., см.__.
 *
 * @type {{
 * accessor: 'diameter',
 * Header: 'Диаметр на высоте 1,3 м., см.',
 * sortable: true,
 * }}
 * @augments Cell
 */
export const diameter = {
  accessor: 'diameter',
  Header: 'Диаметр на высоте 1,3 м., см.',
  sortable: true,
};
