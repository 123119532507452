import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import {
  Table,
  useParamsTable,
} from 'core/uiKit/components/tables/Table';

import { getColumnsOtherTechPlace } from './columns.OtherTechPlace';
import { useFilterQueryParamsOtherTechPlace } from './useFilterQueryParams.OtherTechPlace';

 
/**
 * Компонент таблицы __Иные некапитальные объекты__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {JSX.Element} - JSX.
 * @example -----
 * <TableOtherTechPlace
 *  typeId={typeId}
 *  parentTypeId={3}
 * />
 */
export const TableOtherTechPlace = ({
  typeId,
  parentTypeId,
}) => {
  const { prepareFilters } =
    useFilterQueryParamsOtherTechPlace();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableOtherTechPlace',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } =
    useFetchOghTable(queryKey);

  return (
    <Table
      columns={getColumnsOtherTechPlace()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
