import './select.scss';

import { getFieldError } from 'app/utils/validate/getFieldError';
import cn from 'classnames';
import { useEditOnchangeEffect } from 'core/uiKit/inputs/selects/Select/useEditOnchangeEffect';
import {
  FormControl,
  FormHelperText,
  Icon,
  Input,
  InputLabel,
  MenuItem,
  SelectMUI,
  Tooltip,
} from 'core/uiKit/material-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { useGetFilteredOptions } from './useGetFilteredOptions';

/**
 * Компонент селекта.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.className] - Класс.
 * @param {boolean} [props.dark] - Флаг переключения темя.
 * @param {string} [props.errorText] - Текст ошибки.
 * @param {string} [props.helperText] - Текс помощи.
 * @param {string} [props.label] - Лейбл.
 * @param {boolean} [props._multiple] - Хопа.
 * @param {*} [props.optionKey] - Ключ.
 * @param {*} [props.optionName] - Жопа.
 * @param {Array} [props.options] - Жопа.
 * @param {string} [props.value] - Значение.
 * @param {*} [props.meta] - Жопа.
 * @param {boolean} [props.required] - Флаг обязательности поля.
 * @param {Function} [props.onChange] - Функция изменения значения.
 * @param {Function} [props.withChange] - Функция изменения других полей.
 * @param {boolean} [props.disabled] - Флаг редактирования.
 * @param {*} [props.native] - Жопа.
 * @param {*} [props.withAll] - Жопа.
 * @param {Function} [props.filter] - Функция фильтрации.
 * @param {string} [props.helpTitle] - Вспомогательный текст (подсказка).
 * @param {boolean} [props.isLoading] - Флаг загрузки данных.
 * @returns {React.ReactComponentElement} - Жопа.
 * @example
 * <Select {...props}/>
 */
const Select = ({
  className,
  errorText,
  helperText,
  label,
  _multiple = false,
  optionKey,
  optionName,
  options,
  value,
  meta = {},
  required,
  onChange,
  withChange,
  disabled,
  native = false, // для тестов
  withAll = true,
  filter,
  helpTitle,
  ...otherProps
}) => {
  const error = getFieldError(meta);

  /**
   * Функция изменения инпута с сайд эфектом withChange.
   *
   * @param {Event} event - Объект Event.
   * @returns {void} - Nothing.
   * @example
   * <Component onChange={handleChange}/>
   */
  const handleChange = (event) => {
    const { value } = event.target;
    if (typeof withChange === 'function') {
      withChange(value);
    }

    onChange(value);
  };

  // сбрасываем неправильное значение при переходе в редактирование
  // делаем так потому что надо показывать валуе даже если нет в этого в фильтре но только при просмотре .
  useEditOnchangeEffect({
    editMode: !disabled,
    filter,
    handleChange,
    optionKey,
    options,
    value,
    withChange,
  });

  const getFilteredOptions = useGetFilteredOptions({
    disabled,
    filter,
    optionKey,
    optionName,
    options,
    value,
  });

  const isError = !!error || !!errorText;
  const customValue = options?.length ? value : _multiple ? [] : '';

  return (
    <FormControl
      {...{
        className,
        style: { width: '100%' },
      }}
      disabled={disabled}
      error={isError}
      required={required}
      variant="standard"
    >
      <InputLabel
        id={otherProps.name}
        className={cn('select__label', { label: label.length > 30 })}
      >
        {label}
        {helpTitle ? (
          <Tooltip title={helpTitle}>
            <Icon className={'ml-1'} style={{ fontSize: 14 }}>
              help_outline
            </Icon>
          </Tooltip>
        ) : null}
      </InputLabel>
      <SelectMUI
        {...{
          multiple: _multiple,
          value: customValue,
        }}
        {...otherProps}
        name={otherProps.name}
        labelId={otherProps.name}
        role="select"
        input={<Input />}
        native={native}
        onChange={handleChange}
        SelectDisplayProps={{ 'data-testid': otherProps.name }}
      >
        {withAll && (
          <MenuItem role="menuitem" key={'all'} value={''}>
            Все
          </MenuItem>
        )}

        {getFilteredOptions().map((item, index) => (
          <MenuItem role="menuitem" key={index} value={item[optionKey]}>
            {item[optionName]}
          </MenuItem>
        ))}
      </SelectMUI>
      <FormHelperText>{error || errorText || helperText} &nbsp;</FormHelperText>
    </FormControl>
  );
};

Select.propTypes = {

  /**
   * Быбор темной темы.
   */
  dark: PropTypes.bool,
  defaultValue: PropTypes.any,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Функция фильтрации входящих options
   * принимает на вход массив options
   * (options)=>{ return options }.
   */
  filter: PropTypes.func,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,
  label: PropTypes.string,

  /**
   * Имя селекта в форме.
   */
  name: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Имя ключа по которому будет выбираться значение.
   */
  optionKey: PropTypes.string,

  /**
   * Имя ключа по которому будет отображаться значение.
   */
  optionName: PropTypes.string,

  /**
   * Массив итемов.
   */
  options: PropTypes.array,

  /**
   * Placeholder.
   */
  placeholder: PropTypes.string,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,

  /**
   * Делать ли элемент "Все".
   */
  withAll: PropTypes.bool,
};

Select.defaultProps = {
  dark: false,
  disabled: false,
  errorText: '',
  helperText: '',
  label: 'select label',
  name: 'select',

  /**
   * OnChange.
   *
   * @param {object} value - Value.
   * @returns {void} - Nothing.
   */
  onChange: (value) => {
    value;
  },
  optionKey: 'id',
  optionName: 'name',
  options: [],
  placeholder: '',
  required: false,
};

export { Select };
