import { checkParent } from 'app/api/hooks/helpers/checkParent';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { getOghCard } from 'app/api/requests/getOghCard';
import { turnIntoCamelCase } from 'app/utils/turnIntoCamelCase';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * @typedef {import("types/Card").ParentCardOgh} ParentCardOgh
 */

/**
 * @typedef {{typeId:number, rootId:number, recordId:number, children: Array}} ParentObject - Информация о родителе.
 * @property {boolean} isSuccessParent - Флаг успешный ли запрос.
 */

/**
 * Получение родительского объекта.
 *
 * @returns {ParentCardOgh} - Информация о родителе.
 * @example ----
 */
export const useGetParentCard = () => {
  const currentCard = useGetCurrentCard();
  const { actions, id: urlId } = useGetParamsUrl();
  const isParentTypeId = checkParent(currentCard.typeId);
  let id = 0;

  // У карточки нет родителя в url, но есть информация о родителе в useGetCurrentCard()
  if (
    currentCard?.parentInfo?.parent_id &&
    !actions.haveParent &&
    !isParentTypeId
  ) {
    id = currentCard.parentInfo.parent_id;
  }

  // Проверка на то что текущая карточка является родительской, проверяем из константы isParentTypeId и только когда флаг actions.haveParent false
  if (isParentTypeId && !actions.haveParent && currentCard.successCard) {
    id = currentCard.recordId;
  }

  // При создании карточки, редактировании или просмотре когда в url есть родитель получаем id из url
  if (actions.haveParent) {
    id = urlId;
  }

  const { data = {}, isSuccess } = useQueryAdaptor(
    ['getParentCard', id],
    getOghCard,
    {
      enabled: Boolean(id) && Boolean(currentCard.successCard),
    },
  );

  const dataCamel = turnIntoCamelCase(data);

  return {
    ...data,
    ...dataCamel,
    isSuccessParent: isSuccess,
  };
};
