import { AUTH_URLS_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/AuthUrls';
/* eslint-disable  */
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { useNavigateRouter } from 'core/utils/router';

import { fetchLogin } from '../api';
import { getHistory } from '../utils/getHistory';
import { useGetErrorMessage } from '../utils/useGetErrorMessage';

/**
 * Hook useMutationAuth.
 *
 * @returns {{errorMessage:string, isLoading:boolean, loginSubmit:Function}}
 */
export const useMutationAuth = () => {
  const navigate = useNavigateRouter();

  const [loginSubmit, { isLoading, error }] = useMutationAdaptor(fetchLogin, {
    /**
     *
     * @param response
     * @param data
     */
    onSuccess: (response, data) => {
      if (data[AUTH_URLS_NAME]?.includes('path_api_1.0')) {
        if (response.redirected) {
          return (window.location.href = response.url);
        }
      }
      if (/^login$/.test(data[AUTH_URLS_NAME])) {
        return (window.location.href = '/admin-ui/admin/users');
      }

      if (response?.code === 200) {
        navigate(getHistory());
      }

      if (response?.status === 401) {
        throw response?.message;
      }
    },
  });

  return {
    errorMessage: useGetErrorMessage(error?.message),
    isLoading,
    loginSubmit,
  };
};
