/**
 * @typedef {import('src/core/uiKit/components/tables/Table/Table').Columns} Columns
 */

import { cellsMainMap } from 'core/uiKit/components/cells/cellsMainMap';

/**
 * Columns В реестре Цветники.
 *
 * @returns {Columns} - Жопа.
 * @example
 * getColumnsFlowersGarde()
 */
export const getColumnsFlowersGarde = () => [
  cellsMainMap.short_root_id, //'ID'
  cellsMainMap.section_num, //'Номер участка',
  {
    ...cellsMainMap.green_num,
    Header: 'Номер цветника',
  },
  {
    ...cellsMainMap.total_area,
    Header: 'Общая площадь цветника, кв.м',
  },
  cellsMainMap.total_area_geo, // Площадь объекта по пространственным координатам, кв.м
  cellsMainMap.annuals_area, //'Однолетники, кв.м', __
  cellsMainMap.perennials_area, //'Многолетники и розы, кв.м', __
  cellsMainMap.inert_area, //'Инертный материал (отсыпка), кв.м', __
  cellsMainMap.shrubs_area, //'Декоративные кустарники, кв.м', __
  cellsMainMap.season_shift_area, //'С учетом сезонной сменности, кв.м', __
  cellsMainMap.bulbous_area, //'Луковичные и клубнелуковичные кроме тюльпанов, нарциссов, кв.м', __
  cellsMainMap.tulip_area, //'Тюльпаны, нарциссы, кв.м', __
  cellsMainMap.biennials_area, //'Двулетники (виола), кв.м', __
  cellsMainMap.flinders_area, //'Декорирование щепой, кв.м', __
  cellsMainMap.lawn_area, //'Газоны (как элемент цветника), кв.м, __
  cellsMainMap.rockery_area, // Рокарий (многолетние, однолетние), кв.м __
  cellsMainMap.object_status_name, //'Статус',
  cellsMainMap.sign_date, //'Дата подписания',
  cellsMainMap.sign_person, //'ФИО подписанта',
  cellsMainMap.start_date, //'Дата начала',
  cellsMainMap.end_date, //'Дата окончания',
  cellsMainMap.parent_object_name, //'Наименование родительского объекта',
  cellsMainMap.parent_short_root_id, //'Идентификатор родительского объекта',
  cellsMainMap.parent_okrug_name, //'Округ родительского объекта',
  cellsMainMap.parent_owner_name, //'Балансодержатель родительского объекта',
  cellsMainMap.parent_grbs_name, // ГРБС
  cellsMainMap.is_diff_height_mark, //'Разновысотные отметки',
  cellsMainMap.included_in_tl, // Входит в ТС
];
