import { post } from 'app/api/crud/post';
/* eslint-disable  */
import { useMapContext } from 'app/components/map/useMapContext';
import { FOREGROUND, PARENTS } from 'app/constants/layers';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery/useMutationAdaptor';
import { log } from 'core/utils/log';

/**
 * Запрос на получение геометрии групп.
 *
 * @param {object} params - Параметры запроса.
 * @returns {Promise<*|Response>}
 */
const postGroupGeometry = (params) => post('/ogh/geometry/group', params);

/**
 * Хук использования запроса групп.
 *
 * @returns {{getGroupGeometry: Function, isLoadingGroupGeometry: boolean}}
 */
export const useGroup = () => {
  const { drawParentGeometry, drawCurrentObjectsGeometry, clearLayer } =
    useMapContext();

  const [getGroupGeometry, { isLoading }] = useMutationAdaptor(
    postGroupGeometry,
    {
      /**
       *
       */
      onError: () => {
        log.info('postGroupGeometry не прошел');
      },

      /**
       *
       * @param data
       */
      onSuccess: (data) => {
        clearLayer(PARENTS);
        clearLayer(FOREGROUND);
        drawParentGeometry(data);
        const geo = data[0];
        if (geo?.child_object) {
          drawCurrentObjectsGeometry(geo.child_object);
        }
      },
    },
  );

  return { getGroupGeometry, isLoadingGroupGeometry: isLoading };
};
