import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
/* eslint-disable  */
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { postCheckGeometryContains } from 'app/pages/cardsOgh/api/postCheckGeometryContains';
import { postCheckIntersection } from 'app/pages/cardsOgh/api/postCheckIntersections';
import { useDialogs } from 'app/pages/cardsOgh/components/DIalogs/useDialogs';
import { useMapEdit } from 'app/pages/cardsOgh/components/Map/useMapEdit';
import { usePreloaderCard } from 'app/pages/cardsOgh/components/PreloadedCard/usePreloaderCard';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import { useCreateForm } from 'app/pages/cardsOgh/hooks/useCreateForm';
import {
  CREATE_CARD,
  CREATE_FROM_PARENT,
  UPDATE_CARD,
  UPDATE_FROM_PARENT,
  useTypeActionCard,
} from 'app/pages/cardsOgh/hooks/useTypeActionCard';
import { useUpdateForm } from 'app/pages/cardsOgh/hooks/useUpdateForm';
import { createIntersectionsMessage } from 'app/utils/card/checkGeometry/helpers/createIntersectionsMessage';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * Хук, получения функции сохранения паспортов.
 *
 * @returns {{saveCard: Function}}
 */
export const useSaveCard = () => {
  const card = useGetCurrentCard();
  const parent = useGetParentCard();
  const { id } = useGetObjectId();

  const { updateForm } = useUpdateForm();
  const { fetchCreateForm } = useCreateForm();
  const typeAction = useTypeActionCard();

  const { startPreload, endPreload } = usePreloaderCard();
  const { getGeometry, drawIntersectionsGeometry } = useMapContext();
  const { setHasIntersections } = useMapEdit();
  const { showAlert } = useDialogs();

  /**
   * Функция сохранения с проверкой пересечений.
   *
   * @param {object} params - Параметры.
   * @param {object} params.values - Значения формы.
   * @param {object} params.attribute - Атрибуты.
   * @param {object} params.otherParams - Остальные пропсы.
   * @returns {Promise<{attribute, otherParams: {}}>}
   */
  const saving = async ({ values, attribute, otherParams = {} }) => {
    const geometry = getGeometry(card.recordId);
    startPreload();
    await postCheckGeometryContains(geometry, card, values, id);
    await postCheckIntersection(geometry, card, values, id);
    return { attribute, otherParams, values };
  };

  const [save] = useMutationAdaptor(saving, {
    /**
     *
     * @param root0
     * @param root0.errorKey
     * @param root0.res
     */
    onError: ({ errorKey, res }) => {
      switch (errorKey) {
        case 'errorMore': {
          showAlert(message[errorKey]);
          break;
        }
        case 'errorOther': {
          showAlert(message[errorKey]);
          break;
        }
        case 'errorIntersectionsMore': {
          showAlert(
            createIntersectionsMessage(res.geometry, message[errorKey]),
          );
          break;
        }
        case 'errorIntersections': {
          showAlert(
            createIntersectionsMessage(res.geometry, message[errorKey]),
          );
          break;
        }
        default:
          break;
      }
      endPreload();
      setHasIntersections(res.intersected);
      drawIntersectionsGeometry(res.geometry);
    },

    /**
     *
     * @param root0
     * @param root0.attribute
     * @param root0.otherParams
     * @param root0.values
     */
    onSuccess: ({ attribute, otherParams, values }) => {
      const prepareData = {
        ...getGeometry(card.recordId),
        attribute,
        childObjectTypeId: card.typeId,
        endDate: values.endDate,
        id: card.recordId,
        record_id: card.recordId,
        root_id: card.rootId,
        startDate: values.startDate,
        tree_actions: {},
        tree_count_actions: 0,
        type: card.typeId,
        ...otherParams,
      };

      switch (typeAction) {
        case CREATE_FROM_PARENT: {
          const dataCreate = {
            is_child: true,
            parent_id: parent.recordId,
            root_object_id: parent.recordId,
            root_object_type_id: parent.typeId,
          };
          fetchCreateForm({
            ...prepareData,
            ...dataCreate,
          });
          break;
        }
        case UPDATE_FROM_PARENT: {
          const dataUpdate = {
            is_child: true,
            old_child_object_id: card.recordId,
            parent_id: parent.recordId,
            root_object_id: parent.recordId,
            root_object_type_id: parent.typeId,
          };
          updateForm({ ...prepareData, ...dataUpdate });
          break;
        }
        case CREATE_CARD: {
          const dataCreate = {
            is_child: false,
            parent_id: card.root_id,
            root_object_id: card.rootId,
            root_object_type_id: card.typeId,
          };
          fetchCreateForm({
            ...prepareData,
            ...dataCreate,
          });
          break;
        }
        case UPDATE_CARD: {
          const dataUpdate = {
            is_child: false,
            old_child_object_id: card.recordId,
            root_object_id: card.rootId,
            root_object_type_id: card.typeId,
          };
          updateForm({ ...prepareData, ...dataUpdate });
          break;
        }
        default:
          return null;
      }
    },
  });

  return { saveCard: save };
};
