import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import {
  Table,
  useParamsTable,
} from 'core/uiKit/components/tables/Table';
import React from 'react';

import { getColumnsFlowersGarde } from './columns.FlowersGarden';
import { useFilterQueryParamsFlowersGarden } from './Filter.FlowersGarden';

 
/**
 * Компонент таблицы реестра __Цветники__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id ОГХ текущий.
 * @param {number} props.parentTypeId - Id ОГХ родителя.
 * @returns {JSX.Element} - JSX.
 * @example
 * <TableFlowersGarden typeId={typeId} parentTypeId={parentTypeId}/>
 */
export const TableFlowersGarden = ({
  typeId,
  parentTypeId,
}) => {
  const { prepareFilters } =
    useFilterQueryParamsFlowersGarden();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableFlowersGarden',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } =
    useFetchOghTable(queryKey);

  return (
    <Table
      columns={getColumnsFlowersGarde()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
