import { useSelector } from 'react-redux';

/**
 * Хук получения текущих значения формы паспорта.
 *
 * @returns {object}
 */
export const useFormValuesCardRF = () => {
  return useSelector((state) => state?.form?.editorCard?.values);
};
