import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import {
  Table,
  useParamsTable,
} from 'core/uiKit/components/tables/Table';

import { getColumnsLawn } from './columns.Lawn';
import { useFilterQueryParamsLawn } from './Filter.Lawn';

 
/**
 * Таблица реестров __Газоны__.
 *
 * @param {object} props - Properties.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const TableLawn = ({ typeId, parentTypeId }) => {
  const { prepareFilters } = useFilterQueryParamsLawn();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableLawn',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const { table, isLoading, count } =
    useFetchOghTable(queryKey);

  return (
    <Table
      columns={getColumnsLawn()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
