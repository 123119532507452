/* eslint-disable  */

import { Icon } from 'core/uiKit/components/Icon';
import React from 'react';

import { Accordion } from '../Accordion';
import { ElementsTree } from '../Elements.Tree';
import { SubTypeTree } from '../SubType.Tree';
import { TitleButton } from '../TitleButton';
import { ElementTree, IGroupTree } from '../Tree';
import { useSetupGroupItemTree } from './useSetup.Group.Item.Tree';

/**
 * Элемент группы от дерева элементов.
 *
 * @param {IGroupTree} props - Props.
 * @returns {JSX.Element}
 */
export const GroupItemTree = (props: IGroupTree): JSX.Element => {
  const { title, subTypes, elements, id, typeId, typeCode } = props;

  const {
    active,
    open,
    openSubTypes,
    setOpenSubTypes,
    setOpen,
    onSelectGpoup,
  } = useSetupGroupItemTree(props);

  const items = subTypes?.length
    ? subTypes?.reduce(
        (acc: ElementTree[], curr) => [...acc, ...(curr?.elements || [])],
        [],
      )
    : elements;

  const clOpen = open ? 'fa fa-caret-up' : 'fa fa-caret-down';

  return (
    // @ts-ignore
    <Accordion
      key={id}
      active={active}
      accordionOpen={open}
      setAccordionOpen={(v: boolean) => setOpen(v)}
      containsChild={!!items?.length}
      // onClick={onSelectGpoup}
      HeaderComponent={
        // @ts-ignore
        <TitleButton
          data={{
            id: props.id,
            items,
            pathObjectId: null,
            title: props.title,
            typeCode: props.typeCode,
            typeId: props.typeId,
          }}
          typeCode={typeCode}
          typeId={typeId}
          activeButton={active}
          onClick={() => {}}
        >
          <span
            onClick={() => {
              onSelectGpoup();
            }}
          >
            {`${title} (${items?.length} шт.)`}
          </span>
          <Icon
            onClick={() => setOpenSubTypes(!openSubTypes)}
            baseClassName="fa"
            className={`ms-1 fa-solid ${clOpen}`}
          />
        </TitleButton>
      }
    >
      <SubTypeTree subTypes={subTypes} openSubTypes={openSubTypes} />
      <ElementsTree elements={elements} />
    </Accordion>
  );
};
