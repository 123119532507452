import { useCheckingItIsChildOfImprovementObject } from 'app/pages/registry/ogh/hooks/useCheckingItIsChildOfImprovementObject';
import {
  AddressFilter,
  BuildingsTypeSpecFilter,
  CustomerFilter,
  FilterFF,
  OkrugFilter,
  OwnerIdFilter,
} from 'core/form/filterForm';
import { DateFromFilter } from 'core/form/filterForm/components/dateFilters/DateFromFilter';
import { DateToFilter } from 'core/form/filterForm/components/dateFilters/DateToFilter';
import { OghStatusesFilter } from 'core/form/filterForm/components/miltiSelect/OghStatusesFilter';
import { GrbsIdFilter } from 'core/form/filterForm/components/selects/GrbsId.Filter';
import { IdOrNameFilter } from 'core/form/filterForm/components/text/IdOrName.Filter';
import { IdOrNameParentFilter } from 'core/form/filterForm/components/text/IdOrNameParent.Filter';

import { useFilterQueryParamsOtherTechPlace } from './useFilterQueryParams.OtherTechPlace';

 
/**
 * Filter FF реестров Иные некапитальные объекты.
 *
 * @param {object} params - Parameters.
 * @param {number} params.parentTypeId - Id типа родителя реестра.
 * @param {number} params.typeId - Id типа огх.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const FilterOtherTechPlace = ({
  typeId,
  parentTypeId,
}) => {
  const { setFilters, filters } =
    useFilterQueryParamsOtherTechPlace();
  const { isChildOfImprovementObject } =
    useCheckingItIsChildOfImprovementObject(parentTypeId);
   
/**
 * Фукция submit фильтра формы FF.
 *
 * @param {object} values - Values.
 * @returns {void} - Nothing.
 * @example ---
 */
  const onSubmit = (values) => {
    setFilters(values, 'push');
  };

  /* eslint-disable */
/**
   * Функия сброса формы FF.
   *
   * @param {object} form - Параметры формы.
   * @param {Function} form.reset - Функция сброса формы FF.
   * @returns {void} - Nothing.
   * @example ---------
   */
  const reset = ({ reset }) => {
    reset();
    setFilters({}, 'replace');
  };

  return (
    <FilterFF
      {...{ onSubmit, reset, initialValues: filters }}
    >
      <IdOrNameFilter label={'ID'} />
      <IdOrNameParentFilter />
      <OghStatusesFilter />
      <DateFromFilter />
      <DateToFilter />
      <AddressFilter />
      <BuildingsTypeSpecFilter typeId={typeId} />
      <GrbsIdFilter hidden={isChildOfImprovementObject} />
      <OkrugFilter />
      <CustomerFilter />
      <OwnerIdFilter />
    </FilterFF>
  );
};
