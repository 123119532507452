import env from '@beam-australia/react-env';

import packageJson from '../../package.json';

//
export const PUBLIC_URL = env('PUBLIC_URL');
// if (process.env.NODE_ENV === 'production' && PUBLIC_URL) {
//   // eslint-disable-next-line
//   __webpack_public_path__ = PUBLIC_URL
// }

export const ODS_API_URL =
  window.ODS_API_URL || env('ODS_API_URL') || '/for-test/api';

export const DXF_API_URL = window.DXF_API_URL || env('DXF_API_URL');

export const DXF_EXPORT_API_URL =
  window.DXF_EXPORT_API_URL || env('DXF_EXPORT_API_URL') || '/for-test/api';

export const PRINT_API_URL =
  window.PRINT_API_URL || env('PRINT_API_URL') || '/for-test/print';

export const FRONTEND_VERSION_WITH_BUILD = window.FRONTEND_VERSION || '';

/**
 * Версия frontend из package.json.
 */
export const FRONTEND_VERSION = packageJson.version || '';

export const FRONTEND_BUILD_DATE = window.FRONTEND_BUILD_DATE || '';

/**
 * Временный флаг для показа хитрой авторизации только по флагу ODS-11008.
 */
export const COMMON_LOGIN_FORM =
  window.COMMON_LOGIN_FORM || env('COMMON_LOGIN_FORM');

export const FRONTEND_ENVIRONMENT =
  window.FRONTEND_ENVIRONMENT || env('FRONTEND_ENVIRONMENT');

export const AUTH_1_0_API_URL =
  window.AUTH_1_0_API_URL || env('AUTH_1_0_API_URL');

let EGIP_PASSWORD;
let EGIP_API_URL;
let EGIP_USER;

switch (FRONTEND_ENVIRONMENT) {
  // case 'prod': {
  //   EGIP_PASSWORD = '5+/I)31WP2O8';
  //   EGIP_API_URL = 'https://egip.mos.ru';
  //   EGIP_USER = 'asu_ods2';
  //   break;
  // }
  default: {
    // Сделано для локальной развертки чтобы работала карта
    if (window.location.hostname === 'localhost') {
      EGIP_PASSWORD = 'U02CVL1P';
      EGIP_API_URL = 'https://egiptest.mos.ru';
      EGIP_USER = 'asu_ods2';
    } else {
      EGIP_PASSWORD = '5+/I)31WP2O8';
      EGIP_API_URL = 'https://egip.mos.ru';
      EGIP_USER = 'asu_ods2';
    }
  }
}

export { EGIP_API_URL, EGIP_PASSWORD, EGIP_USER };
