/* eslint-disable  */
import {
  QueryFunction,
  QueryFunctionContext,
  useQuery as useReactQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';

/**
 * Хук получения данных, исключает из параметров запроса первый элемент queryKey.
 *
 * @param queryKey - Уникальный ключ кэша.
 * @param queryFn - Функция запроса получения.
 * @param _options - Options.
 * @returns Данные запроса и прочее.
 * @example ------
 * const getA = (props) => get('url', props);
 * useQueryAdaptor(['этот не будет прокинут в параметры функции', {props}],fn)
.
 */

export type QueryKey = [string, unknown?];
/*
type QueryFn<TParam, TReturn, TAdditionalParam> = {
  (queryKeys: TParam, add: TAdditionalParam): TReturn;
};*/

export declare type QueryFn<
  T_ = unknown,
  TQueryKey extends QueryKey = QueryKey,
> = (
  queryKeys: TQueryKey[1],
  context: QueryFunctionContext<TQueryKey>,
) => T_ | Promise<T_>;

/**
 * Функция, Адаптер для useReactQuery.
 *
 * @template TQueryFnData
 * @template TError
 * @template TData
 * @template TQueryKey
 * @param queryKey - Ключ - параметры Мас.
 * @param queryFn - Ф.
 * @param _options - С.
 * @returns - Ф.iarstra.
 */
export function useQueryAdaptor<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFn<TQueryFnData, TQueryKey>, // QueryFn<TQueryKey[1], TQueryFnData, QueryFunctionContext<TQueryKey>>,
  _options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  > & {
    onError?: (error: TError) => void;
    onSuccess?: (
      data?: TData,
      variables?: TQueryKey[1],
      context?: TQueryFnData,
    ) => void;
  },
): UseQueryResult<TData, TError> {
  /**
   * Кастомизированая функция.
   *
   * @param opt - QueryOptions.
   * @returns Query.
   */
  const queryFunction: QueryFunction<TQueryFnData, TQueryKey> = (opt) => {
    const [, params] = opt.queryKey;
    return queryFn(params, opt);
  };

  const queryRes = useReactQuery({
    ..._options,
    gcTime: 900000, // 15 минут
    queryFn: queryFunction,
    queryKey,
    retry: false,
    retryOnMount: false,
    staleTime: 840000, // 14 минут
  });

  const { error, isError, isSuccess, data } = queryRes;

  /**
   * Внутренняя мемоизированая функция  в случае удачного вызова.
   */
  const onSuccess = useCallback((data: TData) => {
    if (typeof _options?.onSuccess === 'function') {
      const [, variables] = queryKey;
      return _options.onSuccess(data, variables);
    }
  }, []);

  /**
   * Внутренняя мемоизированая функция для неудачного вызова.
   */
  const onError = useCallback((error: TError) => {
    if (typeof _options?.onError === 'function') {
      return _options.onError(error);
    }
  }, []);

  /**
   * Действия в случае неудачного вызова.
   */
  useEffect(() => {
    if (isError && error) {
      onError(error);
    }
  }, [error, isError, onError]);

  /**
   * Действия в случае удачного вызова.
   */
  useEffect(() => {
    if (isSuccess) {
      onSuccess(data);
    }
  }, [data, isSuccess, onSuccess]);

  return queryRes;
}

// interface ErrorCustom {
//   status?: number;
//   errors?: string[];
//   message?: string;
// }
