import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import {
  Table,
  useParamsTable,
} from 'core/uiKit/components/tables/Table';

import { getColumnsOtherOks } from './columns.OtherOks';
import { useFilterQueryParamsOtherOks } from './Filter.OtherOks';

 
/**
 * Компонент таблицы __Иной объект капитального строительства__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id типа огх.
 * @param {number} props.parentTypeId - Id типа родителя.
 * @returns {JSX.Element} - JSX.
 * @example -----
 * <TableFunctionality
 *  typeId={typeId}
 *  parentTypeId={3}
 * />
 */
export const TableOtherOks = ({ typeId, parentTypeId }) => {
  const { prepareFilters } = useFilterQueryParamsOtherOks();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableOtherOks',
    {
      ...queryTable,
      ...prepareFilters,
      parentTypeId,
      typeId,
    },
  ];

  const {
    data: table,
    isLoading,
    count,
  } = useFetchOghTable(queryKey);

  return (
    <Table
      columns={getColumnsOtherOks()}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
