import { OZN, YARD } from 'app/constants/oghTypes';
import { createNumberedList } from 'app/utils/createNumberedList';

/**
 * Площадь механизированной уборки.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {*}
 */
export const getHintAutoCleanArea = (typeId) => {
  const cleaningMethodChildren = [
    '"Плоскостные сооружения".',
    '"Дорожно-тропиночные сети"',
  ];

  switch (typeId) {
    case YARD: {
      cleaningMethodChildren.push({
        children: ['Пешеходный мост', 'Пирс', 'Cухой фонтан'],
        item: '"Инженерные сооружения"',
      });
      break;
    }
    case OZN: {
      cleaningMethodChildren.push({
        children: ['Пешеходный мост', 'Пирс (причал)', 'Променад'],
        item: '"Инженерные сооружения"',
      });
      break;
    }
    default: {
      break;
    }
  }

  return createNumberedList({
    header: 'Рассчитывается как сумма значений',
    list: [
      {
        children: cleaningMethodChildren,
        item: '"Площадь, кв.м." ("Способ уборки": "Механизированная уборка")',
      },
    ],
  });
};
