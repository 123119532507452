import { useFetchOghTable } from 'app/pages/registry/ogh/api';
import {
  Table,
  useParamsTable,
} from 'core/uiKit/components/tables/Table';

import { getColumnsWaterBuilding } from './columns.WaterBuilding';
import { useFilterQueryParamsWaterBuilding } from './Filter.WaterBuilding';

/* eslint-disable */
/**
 * Компонент таблицы __Водных сооружений__.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Id ОГХ текущий.
 * @param {number} props.parentTypeId - Id ОГХ родителя.
 * @returns {JSX.Element} - JSX.
 * @example
 * <TableWaterBuilding typeId={typeId} parentTypeId={parentTypeId}/>
 */
export const TableWaterBuilding = ({
  typeId,
  parentTypeId,
}) => {
  const { prepareFilters } =
    useFilterQueryParamsWaterBuilding();
  const { queryTable } = useParamsTable();

  const queryKey = [
    'TableWaterBuilding',
    {
      ...queryTable,
      ...prepareFilters,
      typeId,
      parentTypeId,
    },
  ];

  const { table, isLoading, count } =
    useFetchOghTable(queryKey);

  return (
    <Table
      columns={getColumnsWaterBuilding() || []}
      data={table || []}
      isLoading={isLoading}
      total={count}
    />
  );
};
