// 35 Системы функционального обеспечения

import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute } from 'app/components/card/common/getParseAttribute';
import { elementFormGrid } from 'app/components/card/common/grid';
import { parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
} from 'app/components/card/ogh/DocumentsTab';
import documentsReducer from 'app/components/card/ogh/DocumentsTab/mapStateToPropsReducer';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { SPA } from 'app/constants/oghTypes';
import { column } from 'app/constants/tables';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import getQuantityDigits from 'app/selectors/card/getQuantityDigits';
import hasQuantityLock from 'app/selectors/card/hasQuantityLock';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { CoatingFaceTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingFaceTypeId.RF';
import { QuantityRf } from 'core/form/reduxForm/fields/prepared/textField/Quantity.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { TextField } from 'core/uiKit/inputs/TextField';
import { COATING_FACE_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingFaceTypeId';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { ArrangeElementTypeIdField } from './components/selects/ArrangeElementTypeId.Field';
import { UnitIdField } from './components/selects/UnitId.Field';
import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['functionality'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 * Компонент FunctionalityCard.
 */
class FunctionalityCard extends React.Component {

  /**
   * Метод render.
   *
   * @returns {JSX.Element} Компонент с полями.
   * @example -----------
   */
  render() {
    const {
      card,
      mode,
      createMode,
      editMode,
      required,
      quantityLock,
      parentTypeId,
      quantityDigits,
      documents,
      isCurrentObjectIsExternalSystem,
    } = this.props;

    const parentSPA = parentTypeId === SPA;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            <CardContainer>
              <ArrangeElementTypeIdField />
              <ParentInfo />
            </CardContainer>

            {elementFormGrid(
              [
                {
                  component: WithReduxFormCheckbox,
                  editable: editMode,
                  formValue: true,
                  id: 'no_calc',
                  label: 'Не учитывать',
                  name: 'no_calc',
                  style: checkboxStyle,
                },
                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <IsDiffHeightMarkRF
                    disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                    justifyContent={'space-between'}
                    labelPlacement={'start'}
                  />
                </div>,
              ],
              2,
              '',
              card.typeId,
            )}

            <Tabs tabs={tabs} />

            <div id="characteristics">
              {elementFormGrid(
                [
                  <div>
                    <QuantityRf
                      digits={quantityDigits}
                      disabled={
                        !(parentSPA
                          ? editMode
                          : editMode &&
                            !quantityLock &&
                            !isCurrentObjectIsExternalSystem)
                      }
                      required={isMandatory(card.type_id, 'quantity', required)}
                    />
                  </div>,
                  <div>
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                  </div>,

                  <div>
                    <UnitIdField />
                  </div>,
                ],
                column.TWO,
                '',
                card.type_id,
              )}
              <CardContainer>
                <SelectRF
                  label={'Материал'}
                  name={'material_id'}
                  options={card.material}
                  required={false}
                  disabled={!editMode}
                />
                <TextField
                  label={'Принадлежность элемента к зоне территории'}
                  disabled={true}
                  value={card.zone_name || ''}
                  name={'zone_name'}
                />
                <CoatingFaceTypeIdRF disabled={!editMode} />
                <NumberFieldRF
                  disabled={!editMode}
                  name={'face_area'}
                  decimalScale={2}
                  label={'Площадь облицовки, кв.м.'}
                />
              </CardContainer>
            </div>

            {DocumentsTab()({
              documents: documents,
              editable: editMode,
              typeId: card.type_id,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

const dictFields = [
  'arrange_element_type_id',
  'unit_id',
  'material_id',
  COATING_FACE_TYPE_ID_NAME,
];

const numericFields = [
  {
    maxLength: 11,
    name: 'quantity',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  { name: 'face_area', positive: true, type: 'decimal', zero: true },
];

/**
 * Валидация.
 *
 * @param {object} values - Значения из формы.
 * @returns {object} - Возвращает ошибку.
 */
const validate = (values) => validateNumeric(values, numericFields);

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {{file_list: Array, attributes: {}}} - Возвращает атрибуты.
 * @example --------
 */
const createAttribute = (formValues) => ({
  ...createDocumentsAttribute(formValues),
});

/**
 * Функция сабмит.
 *
 * @param {object} formValues - Значения формы.
 * @returns {void} - Жопа.
 * @example -------------
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Получение данных из стейта.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {*} - Жопа.
 * @example ---------
 */
export const mapStateToProps = (state, props) => {
  // в функции потому что мок не работает
  const improvementElementDocumentsReducer = documentsReducer({
    oghGroupCode: 'yard_subobject',
  });

  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),

    initialValues: {
      arrange_element_type_id: parse(
        'arrange_element_type_id.arrange_element_type',
      ),
      coating_face_type_id: parse('coating_face_type_id.coating_type'),
      endDate: parseDate(props.card.end_date),
      face_area: parse('face_area'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      material_id: parse('material_id.material'),
      no_calc: parse('no_calc') ? parse('no_calc') : 0,
      parent_name: props.card.parent_info,
      quantity: parse('quantity'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
      unit_id: parse('unit_id.unit'),
    },

    onSubmit: onSubmit.bind({ props }),
    quantityDigits: getQuantityDigits(state),
    quantityLock: hasQuantityLock(state),
    required: isRequired(state, props),
    validate,
  };

  return improvementElementDocumentsReducer(result, state, props);
};

/**
 * Получаем экшены.
 *
 * @param {Function} dispatch - Функция передающая экшены в редьюсер.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} Получаем экшен  changeFieldValue.
 * @example --
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   * Меняет значение в форме.
   *
   * @param {string} field - Название поля.
   * @param {*} value - Значение.
   * @returns {void}
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

/**
 * Обертка для получения значений из хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----------
 */
const FunctionalityCardContainer = (props) => {
  const { typeId } = useGetParentCard();
  return <FunctionalityCard parentTypeId={typeId} {...props} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(FunctionalityCardContainer));
